.lxsy{
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    width: auto;
    .back{
        position: absolute;
        right: 2%;
        top: 0%;
        margin-top: 10px;
        z-index: 20;
    }
}