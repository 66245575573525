
@banner2: banner2;
.@{banner2} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(100vh - 64px);
  width: 100%;
  height: ~"calc(100vh )";
  // min-height: 680px;
  position: relative;
  border-color: #666;
  background: #fff;
  .breadcrumb {
    position: absolute;
    word-wrap: break-word;
    margin: auto auto 20px ;
    font-weight: lighter;
    font-weight: bold;
    
    top:~"calc(52vh)"
  }
  .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
    width: auto;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .bg0 {
    background: url("../../assert/bg.png") center;
    background-size: cover;
    height: 100%;
    // height: ~"calc(100vh )";
    width: ~"calc(100% + 100px)";
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
    }
  }
  .bg1 {
    background: url("../../assert/productBanner.png") center;
    background-size: cover;
    height: 100%;
    width: ~"calc(100% + 100px)";
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .bg2 {
    background: url("../../assert/softBanner.png") center;
    background-size: cover;
    height: 100%;
    width: ~"calc(100% + 100px)";
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .bg3 {
    background: url("../../assert/solutionBanner.png") center;
    background-size: cover;
    height: 100%;
    width: ~"calc(100% + 100px)";
    background-position: center;
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .bg4{
    background: url("../../assert/aboutBanner.png") center;
    background-size: cover;
    height: 100%;
    width: ~"calc(100% + 100px)";
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  &-page {
    position: relative;
    top: 15%;
    max-width: 1200px;
    margin: auto;
 
  }
  &-text-wrapper {
    display: inline-block;
    font-size: 20px;
    color: @template-text-color-light;
    width: 400px;
    text-align: left;
    .@{banner2}-title {
      left: 0;
      margin: auto;
      position: relative;
      font-size: 70px;
    }
    & .@{banner2}-content {
      word-wrap: break-word;
      margin: auto auto 20px;
      font-weight: lighter;
      letter-spacing: 1px;
      // font-weight: bold;
      margin-top: -70px !important;
    }

    button {
      border: 1px solid #fff;
      color: #fff;
      background: transparent;
      transition: background .45s @ease-in, border .45s @ease-in;
      line-height: 36px;
      font-size: 16px;
      height: 36px;
      & span {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        transition: text-shadow .45s @ease-out;
      }
      &:hover {
        color: #fff;
        border-color: @primary-color;
        background: @primary-color;
        transition: background .45s @ease-out, border .45s @ease-out;
        & span {
          text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
        }
      }
    }
  }
  &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
    display: none;
  }
}
.banner3{
  height: 70vh !important;
  .banner2-title{
    margin-top: -35px !important;
  }
  .banner2-content{
    margin-top: -70px !important;
  }
}

.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, .5);
}
.ant-breadcrumb,.ant-breadcrumb-separator,.ant-breadcrumb > span:last-child{
  color: white;
}


@media screen and (max-width: 767px) {
  .@{banner2} {
    height: ~"calc(70vh )";
    &-text-wrapper {
      width: 90%;
      left: 0;
      margin: auto;
      padding: 0;
      text-align: center;
      display: block;
      
      .logo {
        width: 90%;
        left: 0;
      }
    }
    &-text-wrapper {
   
      .@{banner2}-title {
        left: 0;
        margin: 0;  
        position: relative;
        font-size: 3rem;
        text-align: left;
        width: 100vw;
        padding-right: 5%;
        min-width: 320px !important;
      }
      & .@{banner2}-content {
        position: absolute;
        word-wrap: break-word;
        padding-right: 5%;
        font-weight: lighter;
        // font-weight: bold;
        width: 100vw;
        // max-width: 320px;
        font-size: 18px;
        line-height:32px;
        text-align: left;
        min-width: 320px !important;
        margin-top: -45px !important;
      }
    }
  }
  .banner3{
    
    .banner2-title{
      margin-top: -60px !important;
      min-width: 320px !important; 
    }
    .banner2-content{
      margin-top: -60px !important;
      min-width: 320px !important;
    }
  }
}

