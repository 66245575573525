.scrollTop{
    position: fixed;
    background-color: gray;
    z-index: 100;
    bottom: 10%;
    right: 0%;
    box-shadow:2px 2px 6px black ;
    padding: 6px;
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.925);
}