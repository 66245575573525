#Nav0_0 .ant-menu-item > .header0-item-block > .ledvtaub6z8-editor_css {
  color: #fbfbfb;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ledwf9pdefj-editor_css {
  display: none;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ledwft0rblo-editor_css {
  margin: 2rem auto 20px;
}
#Banner2_0 .banner-anim-elem > .home-page > .ledwgwyu4g-editor_css {
  width: 35rem;
  top: 20%;
  margin: -2rem 0 0;
}
#Feature5_0 .home-page > .title-wrapper > .ledx5lhxzim-editor_css {
  font-size: 18px;
}
#Pricing1_0 .ledx7lwr4lt-editor_css {
  // background-image: url('../../assert/bg3.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}

// #Pricing1_0,#Pricing1_1,#Teams1_0 {
//   // background-image: url('../../assert/bg3.png');
//   background-color: rgba(238, 231, 231, 0.61);
// }
#Pricing1_1 > .home-page {
  background-color: white;
}
#Pricing1_0{
  background-image: url('../../assert/bg3.png');
  // background-repeat:no-repeat;
  background-size: 100%;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .ledx9fsdvbo-editor_css {
  display: none;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .ledx9jrm6b-editor_css {
  display: none;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .ledx9kze84o-editor_css {
  display: none;
}
#Pricing1_0 .ant-row > .ant-col > .ledx9yvky67-editor_css {
  // background-image: url('../../assert/Pricing2.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
 
}
#Pricing1_0 .ant-row > .ant-col > .ledxa5reobt-editor_css {
  // background-image: url('../../assert/Pricing3.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}

#Pricing1_0 .ant-row > .ant-col > .ledxa7i8dz-editor_css {
  // background-image: url('../../assert/Pricing1.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_0 .home-page > .pricing1-title-wrapper > .ledxakw154-editor_css {
  font-size: 32px;
  color: rgb(251, 251, 251);
  height: 50px;
}
#Pricing1_0 .home-page > .pricing1-title-wrapper > .ledxe8kb16q-editor_css {
  font-size: 18px;
  font-weight: normal;
  color: rgb(251, 251, 251);
}
#Content5_0 .home-page > .title-wrapper > .ledxpbuehzp-editor_css {
  color: #fbfbfb;
}
#Content5_0 .home-page > .title-wrapper > .ledxnzesoa-editor_css {
  background-clip: padding-box;
  font-size: 18px;
  color: #fbfbfb;
}
#Content5_0 .ledxpi5tilj-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .ledyhtbjaai-editor_css {
  background-clip: padding-box;
}

.pricing1-line {
  display: none;
}
// 修改card边框长度
.pricing1-wrapper .pricing1-block-box{
  border: none;
  height: auto;
  // min-height: 500px;
}
#Teams1_0 .ant-row > .ant-col > .lef1bs195n-editor_css {
  display: none;
}
#Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css {
  min-height: 28rem;
  background-image: url('../../assert/partner2.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 90%;
  background-clip: padding-box;
  background-position: 0% 0;
}
#Teams1_0 div > .ant-row > .lef19irw1bh-editor_css {
  min-height: 28rem;
  background-image: url('../../assert/partner1.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 90%;
  background-clip: padding-box;
  background-position: 100% 0;
}
#Teams1_0 .ant-row > .ant-col > .lef1gpupl1-editor_css {
  display: none;
}
#Teams1_0 .home-page > .title-wrapper > .lef1j5kres-editor_css {
  color: #fbfbfb;
}
#Teams1_0 .home-page > .title-wrapper > .lef1jam14s-editor_css {
  font-size: 18px;
  color: #fbfbfb;
}
#Teams1_0 .lef1h1clqw7-editor_css {
  min-height: 520px;
  // height: 1000px;
  // background-image: url('../../assert/bg4.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat:no-repeat;
  // background-size: auto;
  // background-clip: padding-box;
  
}
#Teams1_0  {
   background-image: url('../../assert/bg4.png');
  //  background-repeat:no-repeat;
  background-size: 100% 100%;
  min-height: 803px;
}
#Pricing1_1 .home-page > .pricing1-title-wrapper > .lemg14jwgu-editor_css {
  font-size: 18px;
  font-weight: normal;
}
#Pricing1_1 .ant-row > .ant-col > .lemg6419i28-editor_css {
  // background-image: url('../../assert/project2.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat:no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;

}
#Pricing1_1 .ant-row > .ant-col > .lemg6cywfyl-editor_css {
  // background-image: url('../../assert/project1.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_1 .ant-row > .ant-col > .lemg6egoak-editor_css {
  // background-image: url('../../assert/project3.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100% ;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_1
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .lemg6h95uq-editor_css {
  display: none;
}
#Pricing1_1
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .lemg6mhdhx-editor_css {
  display: none;
}
#Pricing1_1
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .lemg6o6e2t9-editor_css {
  display: none;
}


@media screen and (max-width: 767px) {

  #Banner2_0 .home-page > .banner2-text-wrapper > .ledwft0rblo-editor_css {
    margin: -1rem auto 20px;
  }
  .header0-menu .ant-menu-inline .ant-menu-item {
    text-align: left;
  }
  #Teams1_0 {
    background-size: auto;
    background-position: 50% 0%;
  }
  #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css,#Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css{
    min-height: 60vw;
    // height: auto;
    max-width: 90%;
  }
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css, #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css{
    background-position: 110% 0%;
  }
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css{
    margin-top: -20px;
  }
  .pricing1-wrapper .pricing1-block-box{
    min-height: 425px;
  }
  #Pricing1_1 .ant-row > .ant-col > .lemg6cywfyl-editor_css
  ,#Pricing1_1 .ant-row > .ant-col > .lemg6egoak-editor_css 
  ,#Pricing1_1 .ant-row > .ant-col > .lemg6419i28-editor_css,
  #Pricing1_0 .ant-row > .ant-col > .ledxa7i8dz-editor_css,
#Pricing1_0 .ant-row > .ant-col > .ledxa5reobt-editor_css,
#Pricing1_0 .ant-row > .ant-col > .ledx9yvky67-editor_css{
    width: 290px;
    // height: auto;
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected{
  background-color:transparent;
}

.home-page-wrapper .home-page{
max-width: 1350px;
// min-height: 800px;
}
.content0-wrapper .content0-block-wrapper{
  padding: 0;
}
.content0-wrapper .content0{
  padding: 0 10px;
}
.header0-item-block{
  color: white;
}
.ant-btn-round.ant-btn-lg{
  border-color: #3563B3;
  color:#3563B3 ;
}
@media screen and (max-width: 320px){
  #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css,#Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css{
    min-height: 192px;
    // height: auto;
  }
}