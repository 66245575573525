@pricing1: pricing1;
.@{pricing1}-wrapper {
  min-height: 950px;
  .@{pricing1} {
    >p {
      text-align: center;
    }
    &-content-wrapper {
      min-height: 420px;
    }
    &-block-box {
      width: 260px;
      border-radius: 4px;
      background-color: rgba(209, 235, 240, 0);
      text-align: center;
      // color: #666;
      // height: 0;
      // height: 37vw;
      // min-height: 500px;
      // padding-bottom: 100%;
      margin: auto;
      border: none;
      .page-pro();
      &.active {
        border-color: @primary-color;
        background: rgb(185, 131, 131);
        .@{pricing1} {
          &-top-wrapper {
            background: @primary-color;
          }
          &-name,
          &-money,
          &-button {
            color: #fff;
          }
          &-button {
            background: @primary-color;
          }
        }
      }
    }
    &-block {
      margin-bottom: 24px;
    }
    &-top-wrapper {
      width: 100%;
      // padding: 16px 24px;
    }
    &-name {
      font-size: 14px;
    }
    &-money {
      font-family: 'Helvetica Neue', sans-serif;
      font-size: 32px;
      color: #666;
    }
    &-content {
      // font-size: 12px;
      // line-height: 2;
      // font-weight: 300;
      // margin: 32px 24px 48px;
      
    }
    &-line {
      display: none;
      height: 1px;
      background: #d9d9d9;
      margin: 0 24px;
    }
    &-button-wrapper {
      // margin: 18px 24px;
      display: none;
    }
    &-button {
      padding: 0 24px;
      display: none;
    }
  }
  &.home-page-wrapper {
    .@{pricing1}-title-wrapper {
      margin-bottom: 64px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{pricing1}-wrapper {
    padding-bottom: 0;
  }
}
