body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #001529;
  width: 100%;
  z-index: 100;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0%;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 64px;
  overflow: hidden;
}
.header0-logo img {
  width: 150px;
  height: 64px;
  vertical-align: middle;
  display: inline-block;
  transform: scale(2.5, 2.5);
  -webkit-transform: scale(2.5, 2.5);
  -moz-transform: scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  -o-transform: scale(2.5, 2.5);
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
  margin-right: -50px;
}
.header0-menu .ant-menu {
  line-height: 62px;
  height: 64px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item li #only,
.header0-item-child li #only,
.header0-menu li #only {
  color: white;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
  text-align: center;
}
.header0-item .ant-menu-sub .ant-menu-item:hover .item-title,
.header0-item-child .ant-menu-sub .ant-menu-item:hover .item-title,
.header0-menu .ant-menu-sub .ant-menu-item:hover .item-title {
  color: #a7c0f0;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
}
.header0-item .item-title:hover,
.header0-item-child .item-title:hover,
.header0-menu .item-title:hover {
  color: #a7c0f0;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
.header0 {
  background: transparent;
  position: fixed;
}
.header0-menu > .ant-menu-dark {
  background-color: transparent;
}
.nav-backgroud {
  transition: background 0.5s;
}
@media screen and (max-width: 767px) {
  .header0 {
    background: rgba(0, 0, 0, 0.75);
  }
  .header0-logo {
    z-index: 101;
  }
  .header0-logo img {
    vertical-align: middle;
    display: inline-block;
    transform: scale(2.5, 2.5);
    -webkit-transform: scale(2.5, 2.5);
    -moz-transform: scale(2.5, 2.5);
    -ms-transform: scale(2.5, 2.5);
    -o-transform: scale(2.5, 2.5);
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu li #only {
    width: 100%;
    text-align: left;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh );
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .breadcrumb {
  position: absolute;
  word-wrap: break-word;
  margin: auto auto 20px ;
  font-weight: lighter;
  font-weight: bold;
  top: calc(52vh);
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url("../../assert/bg.png") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg1 {
  background: url("../../assert/productBanner.png") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg1:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg2 {
  background: url("../../assert/softBanner.png") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg2:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg3 {
  background: url("../../assert/solutionBanner.png") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  background-position: center;
  left: -50px;
}
.banner2 .bg3:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg4 {
  background: url("../../assert/aboutBanner.png") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg4:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: 15%;
  max-width: 1200px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 20px;
  color: #fff;
  width: 400px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 70px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-top: -70px !important;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
  display: none;
}
.banner3 {
  height: 70vh !important;
}
.banner3 .banner2-title {
  margin-top: -35px !important;
}
.banner3 .banner2-content {
  margin-top: -70px !important;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
.ant-breadcrumb,
.ant-breadcrumb-separator,
.ant-breadcrumb > span:last-child {
  color: white;
}
@media screen and (max-width: 767px) {
  .banner2 {
    height: calc(70vh );
  }
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
  .banner2-text-wrapper .banner2-title {
    left: 0;
    margin: 0;
    position: relative;
    font-size: 3rem;
    text-align: left;
    width: 100vw;
    padding-right: 5%;
    min-width: 320px !important;
  }
  .banner2-text-wrapper .banner2-content {
    position: absolute;
    word-wrap: break-word;
    padding-right: 5%;
    font-weight: lighter;
    width: 100vw;
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    min-width: 320px !important;
    margin-top: -45px !important;
  }
  .banner3 .banner2-title {
    margin-top: -60px !important;
    min-width: 320px !important;
  }
  .banner3 .banner2-content {
    margin-top: -60px !important;
    min-width: 320px !important;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 0 24px 10rem;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
.pricing1-wrapper {
  min-height: 950px;
}
.pricing1-wrapper .pricing1 > p {
  text-align: center;
}
.pricing1-wrapper .pricing1-content-wrapper {
  min-height: 420px;
}
.pricing1-wrapper .pricing1-block-box {
  width: 260px;
  border-radius: 4px;
  background-color: rgba(209, 235, 240, 0);
  text-align: center;
  margin: auto;
  border: none;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.pricing1-wrapper .pricing1-block-box:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.pricing1-wrapper .pricing1-block-box.active {
  border-color: #1890ff;
  background: #b98383;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-top-wrapper {
  background: #1890ff;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-name,
.pricing1-wrapper .pricing1-block-box.active .pricing1-money,
.pricing1-wrapper .pricing1-block-box.active .pricing1-button {
  color: #fff;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-button {
  background: #1890ff;
}
.pricing1-wrapper .pricing1-block {
  margin-bottom: 24px;
}
.pricing1-wrapper .pricing1-top-wrapper {
  width: 100%;
}
.pricing1-wrapper .pricing1-name {
  font-size: 14px;
}
.pricing1-wrapper .pricing1-money {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 32px;
  color: #666;
}
.pricing1-wrapper .pricing1-line {
  display: none;
  height: 1px;
  background: #d9d9d9;
  margin: 0 24px;
}
.pricing1-wrapper .pricing1-button-wrapper {
  display: none;
}
.pricing1-wrapper .pricing1-button {
  padding: 0 24px;
  display: none;
}
.pricing1-wrapper.home-page-wrapper .pricing1-title-wrapper {
  margin-bottom: 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pricing1-wrapper {
    padding-bottom: 0;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100%;
  height: 100%;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
.content0-wrapper .content0-block-detail {
  text-align: left;
  font-weight: bolder;
  color: #000000;
}
.content0-wrapper .content0-block-skip {
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
}
.content0-wrapper .content0-block-skip .right-icon {
  width: 50px;
  float: right;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 120px;
}
.footer1-wrapper .block .logo img {
  margin-top: -38px;
  transform: scale(150%);
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
  min-height: 280px;
}
.footer1-wrapper .block .slogan .test {
  margin-top: -32px;
  color: white;
  height: 150px;
  font-size: 14px;
}
.footer1-wrapper .block .slogan .test .title {
  font-size: 16px;
}
.footer1-wrapper .block .slogan .test .qrcode {
  display: flex;
  margin-top: 16px;
}
.footer1-wrapper .block .slogan .test .qrcode .first {
  margin-right: 64px;
}
.footer1-wrapper .block .slogan .test ul {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}
.footer1-wrapper .block .slogan .test ul li {
  margin-top: 8px;
}
.footer1-wrapper .block .slogan .test ul .left {
  margin-right: 64px;
}
.footer1-wrapper .block .slogan .test img {
  width: 150px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  height: 80px;
  text-align: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 .block .slogan {
    min-height: 480px;
  }
  .footer1 .block .slogan .test {
    width: 100%;
    margin: 0 auto;
  }
  .footer1 .block .slogan .test ul li {
    white-space: normal;
    text-align: left;
  }
  .footer1 .block .slogan .test .qrcode {
    display: block;
  }
  .footer1 .block .slogan .test .qrcode .first {
    margin: 6px auto;
  }
  .footer1 .block .slogan .test .qrcode div {
    margin: 6px auto;
  }
  .footer1 .cancel {
    display: none;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 100%;
  }
}
.content1-wrapper {
  height: 100%;
  margin-top: 20px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 80%;
  margin-top: 10%;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 80px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: bolder;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content1-content {
  margin-top: 40px;
  font-size: 16px;
  letter-spacing: 3px;
}
.content1-wrapper .content1-text .content1-button {
  margin-top: 40px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 650px;
  }
  .content1-wrapper .content1-img {
    height: 250px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 100%;
    max-width: 290px;
    min-width: 200px;
    height: 250px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-content {
    font-size: 14px;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 60px auto 16px;
    font-size: 24px;
  }
  .content1-wrapper .content1-text .content1-button {
    margin-top: 30px;
  }
}
.content2-wrapper {
  height: 100%;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 80%;
  margin-top: 10%;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 80px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: bold;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 40px !important;
  font-size: 16px;
  letter-spacing: 3px;
}
.content2-wrapper .content2-text .content2-button {
  margin-top: 40px !important;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 650px;
  }
  .content2-wrapper .content2-img {
    height: 250px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 100%;
    max-width: 290px;
    min-width: 200px;
    height: 250px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 60px auto 16px;
    font-size: 24px;
  }
  .content2-wrapper .content2-text .content2-content {
    font-size: 14px;
  }
  .content2-wrapper .content2-text .content2-button {
    margin-top: 30px !important;
  }
}
#Nav0_0 .ant-menu-item > .header0-item-block > .ledvtaub6z8-editor_css {
  color: #fbfbfb;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ledwf9pdefj-editor_css {
  display: none;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ledwft0rblo-editor_css {
  margin: 2rem auto 20px;
}
#Banner2_0 .banner-anim-elem > .home-page > .ledwgwyu4g-editor_css {
  width: 35rem;
  top: 20%;
  margin: -2rem 0 0;
}
#Feature5_0 .home-page > .title-wrapper > .ledx5lhxzim-editor_css {
  font-size: 18px;
}
#Pricing1_0 .ledx7lwr4lt-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Pricing1_1 > .home-page {
  background-color: white;
}
#Pricing1_0 {
  background-image: url('../../assert/bg3.png');
  background-size: 100%;
}
#Pricing1_0 .pricing1-block-box > .pricing1-button-wrapper > .ledx9fsdvbo-editor_css {
  display: none;
}
#Pricing1_0 .pricing1-block-box > .pricing1-button-wrapper > .ledx9jrm6b-editor_css {
  display: none;
}
#Pricing1_0 .pricing1-block-box > .pricing1-button-wrapper > .ledx9kze84o-editor_css {
  display: none;
}
#Pricing1_0 .ant-row > .ant-col > .ledx9yvky67-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_0 .ant-row > .ant-col > .ledxa5reobt-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_0 .ant-row > .ant-col > .ledxa7i8dz-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_0 .home-page > .pricing1-title-wrapper > .ledxakw154-editor_css {
  font-size: 32px;
  color: #fbfbfb;
  height: 50px;
}
#Pricing1_0 .home-page > .pricing1-title-wrapper > .ledxe8kb16q-editor_css {
  font-size: 18px;
  font-weight: normal;
  color: #fbfbfb;
}
#Content5_0 .home-page > .title-wrapper > .ledxpbuehzp-editor_css {
  color: #fbfbfb;
}
#Content5_0 .home-page > .title-wrapper > .ledxnzesoa-editor_css {
  background-clip: padding-box;
  font-size: 18px;
  color: #fbfbfb;
}
#Content5_0 .ledxpi5tilj-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .ledyhtbjaai-editor_css {
  background-clip: padding-box;
}
.pricing1-line {
  display: none;
}
.pricing1-wrapper .pricing1-block-box {
  border: none;
  height: auto;
}
#Teams1_0 .ant-row > .ant-col > .lef1bs195n-editor_css {
  display: none;
}
#Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css {
  min-height: 28rem;
  background-image: url('../../assert/partner2.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 90%;
  background-clip: padding-box;
  background-position: 0% 0;
}
#Teams1_0 div > .ant-row > .lef19irw1bh-editor_css {
  min-height: 28rem;
  background-image: url('../../assert/partner1.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 90%;
  background-clip: padding-box;
  background-position: 100% 0;
}
#Teams1_0 .ant-row > .ant-col > .lef1gpupl1-editor_css {
  display: none;
}
#Teams1_0 .home-page > .title-wrapper > .lef1j5kres-editor_css {
  color: #fbfbfb;
}
#Teams1_0 .home-page > .title-wrapper > .lef1jam14s-editor_css {
  font-size: 18px;
  color: #fbfbfb;
}
#Teams1_0 .lef1h1clqw7-editor_css {
  min-height: 520px;
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
}
#Teams1_0 {
  background-image: url('../../assert/bg4.png');
  background-size: 100% 100%;
  min-height: 803px;
}
#Pricing1_1 .home-page > .pricing1-title-wrapper > .lemg14jwgu-editor_css {
  font-size: 18px;
  font-weight: normal;
}
#Pricing1_1 .ant-row > .ant-col > .lemg6419i28-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_1 .ant-row > .ant-col > .lemg6cywfyl-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_1 .ant-row > .ant-col > .lemg6egoak-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100% ;
  background-clip: padding-box;
  width: 80%;
}
#Pricing1_1 .pricing1-block-box > .pricing1-button-wrapper > .lemg6h95uq-editor_css {
  display: none;
}
#Pricing1_1 .pricing1-block-box > .pricing1-button-wrapper > .lemg6mhdhx-editor_css {
  display: none;
}
#Pricing1_1 .pricing1-block-box > .pricing1-button-wrapper > .lemg6o6e2t9-editor_css {
  display: none;
}
@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .ledwft0rblo-editor_css {
    margin: -1rem auto 20px;
  }
  .header0-menu .ant-menu-inline .ant-menu-item {
    text-align: left;
  }
  #Teams1_0 {
    background-size: auto;
    background-position: 50% 0%;
  }
  #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css,
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css {
    min-height: 60vw;
    max-width: 90%;
  }
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css,
  #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css {
    background-position: 110% 0%;
  }
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css {
    margin-top: -20px;
  }
  .pricing1-wrapper .pricing1-block-box {
    min-height: 425px;
  }
  #Pricing1_1 .ant-row > .ant-col > .lemg6cywfyl-editor_css,
  #Pricing1_1 .ant-row > .ant-col > .lemg6egoak-editor_css,
  #Pricing1_1 .ant-row > .ant-col > .lemg6419i28-editor_css,
  #Pricing1_0 .ant-row > .ant-col > .ledxa7i8dz-editor_css,
  #Pricing1_0 .ant-row > .ant-col > .ledxa5reobt-editor_css,
  #Pricing1_0 .ant-row > .ant-col > .ledx9yvky67-editor_css {
    width: 290px;
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.home-page-wrapper .home-page {
  max-width: 1350px;
}
.content0-wrapper .content0-block-wrapper {
  padding: 0;
}
.content0-wrapper .content0 {
  padding: 0 10px;
}
.header0-item-block {
  color: white;
}
.ant-btn-round.ant-btn-lg {
  border-color: #3563B3;
  color: #3563B3 ;
}
@media screen and (max-width: 320px) {
  #Teams1_0 div > .ant-row > .lef19irw1bh-editor_css,
  #Teams1_0 div > .ant-row > .lef19fnzs0p-editor_css {
    min-height: 192px;
  }
}
