.products_content{
    max-width: 1200px;
    margin:  0 auto;
    // background-color: red;
    color: #999999;
    letter-spacing: 1px;
    line-height: 28px;
    .line{
        margin-top: 24px;
        margin-bottom: 32px;
        background-color: #3563B3;
        width: 64px;
        height: 3px;
    }
    .line-text{
        max-width: 800px;
        margin: 0 auto;
    }
    .line-other{
        margin: 12px 0px !important;
    }
    .odd{
        background-color: white;
        padding: 24px 0 24px 0 !important
    }
    .page{
        text-align: center;
        padding: 24px 0 24px 0;
        .title{
            display: block;
            margin-top: 50px;
            margin-bottom: 60px;
            h1{
                font-size: 42px;
                font-weight: bolder;
                padding: 0 16px;
                line-height: 1.5;
            }
            h3{
                font-weight: bold;
                font-size: 22px;
            }
        }
        .title-second{
            display: block;
            margin-top: 20px;
            margin-bottom: 48px;
            padding: 0 12px;
            h2{
                font-weight: bold;
                font-size: 28px;
            }
            .line{
                margin: 18px auto;
            }
        }
        .right{
            float: right;
        }
        .left{
            float: left;
        }
        .introduce{
            width: 80%;
            margin: 0 auto 16px;
            // background-color: red;
            text-align: left;
            padding:6px 32px;
          // line-height: 28px;
           
            h3{
                font-weight: bold;
                font-size: 22px;
            }
            .introduce-extra{
                margin: 18px 0;
            }
            .noLine{
                margin-bottom: 12px;
            }
            .introduce-details{
                padding: 0 8px ;
                .introduce-content{
                    display: flex;
                    .circle,.content{
                        vertical-align: middle;
                    }
                   .circle{
                       background-color: #3563B3;
                       min-width: 20px;
                       height:20px;
                       border-radius: 50%;
                      color: white;
                      text-align: center;
                      margin-right: 6px;
                      line-height: 20px;
                      margin-top: 5px;
                     
                   }
                   content{
                    width: auto;
                    line-height: 16px;
                   }
                }
          
            }
            .introduce-ul{
                margin-left: 12px;
                list-style: none;
                .li-other{
                    display: flex;
                }
                li{
                    font-family: 'PingFangSC-Regular';
                    font-size: 13px;
                    .li-content{
                        color:#4D4D4D;
                        div{
                            color:#999999 ;
                        }
                    }
                    img{
                        width: 14px;
                        margin-right: 8px;
                    }
                }
            }
            .introduce-img{
                display: flex;
                justify-content: end;
                img{
                    display: block;
                    width: 200px;
                }
            }
            
        }
        .phone{
            margin: 15% 0 !important;       
           }
        .top{
            margin: 10% 0 !important
        }
        .other{
            margin-top:  24px !important;
        }
        .introdece-img{
            display: flex;   
            width: 80%;
            margin: 0 auto;
            height: inherit;
            padding: 0 16px;

            img{
                width: 100%;
            }
            .special{
                margin-right: -15px !important;
            }
            .ams-icon{
                width: 5%;
                margin:15% 15px;
                img{
                    width: 100%;
                }
            }
            .ams-img{
                width: 90%;
                img{
                    width: 100%;;
                    height: 100%;
                }
            }
        }
        .other-img{
            margin: 20% auto;
        }
    }
    .footer{
        background-image: url('../../../assert/AMSfooter.png');
        h2{
            font-weight: 500 !important;
        }
        .footer-buttom{
            color:  white !important;
            margin-top: 48px;
        }
    }
}

@media screen and (max-width: 767px){
    .products_content{
        .page{
            .title{
            }
            .introduce{
                width: 100%;
                .line{
                 
                }
            }
            .introdece-img{
                width: 90%;
                float: none;
                padding: 16px  !important;
                .ams-icon{
                    margin:32px 0 16px 12px !important;
                }
                .ams-other-img{
                    width: 90%;
                }
                .ams-other-img2{
                    width: 50%;
                }

            }
            .other-img{
                margin: 0 auto;
            }
        }
    }
}