.products_content{
    max-width: 1200px;
    margin:  0 auto;
    // background-color: red;
    color: #999999;
    letter-spacing: 1px;
    line-height: 28px;
    .line{
        margin: 16px 0px 12px;
        background-color: #3563B3;
        width: 64px;
        height: 3px;
    }
    .line-text{
        max-width: 800px;
        margin: 0 auto;
    }
    .line-other{
        margin: 12px 0px !important;
    }
    // 卡片样式
    .row-width{
        width: 80%;
        margin: 0 auto;
        padding: 0 24px;
        .row-card{
            box-shadow: 0px 0px 8px rgb(216, 216, 216);
            border-radius: 10px;
            padding: 24px 0;
            .card-img{
                width: 100px;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .card-content{
                width: 180px;
                margin: 0 auto;
                .card-content-title{
                     margin: 16px 0;
                     color: #282728;
                     font-weight: bold;
                     font-size: 16px;
                }
                .card-content-detail{
                    text-align: left;
                    font-size: 13px;
                    color: #666666;
                    font-weight: 500;
                    margin-top: -16px;
                    line-height: 24px;
                    min-height: 105px;
                    padding: 0 4px;
                }
                .park{
                    min-height: 120px !important;
                }
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        justify-content: center;
                        color: #666666;
                        font-weight: 500;
                        font-size: 13px;
                        .list{
                            margin:10px 6px 0 0; 
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: #3563B3;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .row-card2{
            background-color: #FFFFFF;
            padding: 0 0 16px ;
            text-align: left;
            height: initial;
            .card-img{
                width: inherit;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .card-content{
                line-height: 24px;
                padding: 0 12px;
                width: inherit;
                margin: 0 auto;
                min-height: 180px;
                .card-content-title{
                     margin: 16px 0 12px;
                     color: #282728;
                     font-weight: bold;
                     font-size: 16px;
                }
                .card-content-detail{
                    text-align: left;
                    font-size: 13px;
                    color: #666666;
                    font-weight: 500;
                }
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        justify-content: center;
                        color: #666666;
                        font-weight: 500;
                        font-size: 13px;
                        .list{
                            margin:10px 6px 0 0; 
                            min-width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: #3563B3;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
    .odd{
        background-color: white;
        padding: 36px 0 60px 0 !important
    }
    .page{
        text-align: center;
        padding: 24px 0 24px 0;
        .title{
            display: block;
            margin-top: 50px;
            margin-bottom: 60px;
            h1{
                font-size: 42px;
                font-weight: bolder;
                padding: 0 16px;
                line-height: 1.5;
            }
            h3{
                font-weight: bold;
                font-size: 22px;
            }
            div{
                margin-top: 28px;
               color: #292929;
               font-weight: 500;
            }
        }
        .other-title{
            margin-bottom: 40px !important;
        }
        .title-second{
            display: block;
            margin-top: 20px;
            margin-bottom: 48px;
            padding: 0 12px;
            h2{
                font-weight: bold;
                font-size: 28px;
            }
            .line{
                margin: 20px auto;
            }
        }
        .right{
            float: right;
        }
        .left{
            float: left;
        }
        .out{
            position: absolute;
            z-index: 2;
            right: -15%;
            padding:12px 24px !important;
            background-color: #F8F8F8;
            font-size: 13px !important;
            color: #000000 !important;
        }
        .introduce{
            width: 80%;
            margin: 0 auto 16px;
            // background-color: red;
            text-align: left;
            padding:6px 32px;
          // line-height: 28px;
          .float-icon{
            position: absolute;
            right: -5%;
            top:40%;
            width: 35px;
            img{
                width: 100%;
            }
        }
            h3{
                font-weight: bold;
                font-size: 22px;
            }
            h2{
                font-weight: bold;
                font-size: 28px;
            }
            .line{
                margin: 8px 0 8px 0;
            }
            .introduce-extra{
                margin: 18px 0;
            }
            .noLine{
                margin-bottom: 12px;
            }
            .introduce-details{
                padding: 0 4px ;
                b{
                    color: #282728;
                }
                .introduce-content{
                    display: flex;
                    .circle,.content{
                        vertical-align: middle;
                    }
                   .circle{
                       background-color: #3563B3;
                       min-width: 20px;
                       height:20px;
                       border-radius: 50%;
                      color: white;
                      text-align: center;
                      margin-right: 6px;
                      line-height: 20px;
                      margin-top: 5px;
                     
                   }
                   content{
                    width: auto;
                    line-height: 16px;
                   }
                }
          
            }
            .introduce-ul{
                margin-left: 12px;
                list-style: none;
                .li-other{
                    display: flex;
                }
                li{
                    font-family: 'PingFangSC-Regular';
                    font-size: 13px;
                    .li-content{
                        color:#4D4D4D;
                        div{
                            color:#999999 ;
                        }
                    }
                    img{
                        width: 14px;
                        margin-right: 8px;
                    }
                }
            }
            .introduce-img{
                position: relative;
                display: flex;
                justify-content: end;
                img{
                    display: block;
                    width: 200px;
                }
               
            }
           
            
        }
        .phone{
            margin: 15% 0 !important;       
           }
        .top{
            margin: 10% 0 !important
        }
        .other{
            margin:  8px  0 !important;
        }
        .introdece-img{
            position: relative;
            display: flex;   
            width: 80%;
            margin: 0 auto;
            height: inherit;
            padding: 0 16px;
            .map{
                text-align: left;
                position: absolute;
                background-color: #FFFFFF;
                padding: 32px;
                top: 10%;
                left: 5%;
                border-radius: 10px;
                .map-title{
                    color: #000000;
                    font-size: 18px;
                    font-weight: bold;
                }
                .map-content{
                    font-size: 13px;
                    margin-top: 28px;
                    padding: 8px 0 24px 0;
                    border-top: #DEDEDE 1px solid;
                    font-family: 'PingFangSC-Regular';
                }
            }
            img{
                width: 100%;
            }
            .special{
                margin-right: -15px !important;
            }
            .ams-icon{
                width: 5%;
                margin:15% 15px;
                img{
                    width: 100%;
                }
            }
            .ams-img{
                width: 90%;
                img{
                    width: 100%;;
                    height: 100%;
                }
            }
        }
        .other-img{
            margin: 20% auto;
        }
    }
  
}

@media screen and (max-width: 767px){
    .products_content{
        .page{
            .title{
            }
            .out{
                position: static;
                
            }
            .card-content{
               
                ul{
                    li{
                        // justify-content: start !important;
                        .list{
                            margin: 10px 6px 0 0 !important;
                        }
                    }
                }
            }
            .introduce{
                width: 100%;
                .line{
                 
                }
                .float-icon{
                    display: none;
                }
               
            }
            .row-width{
                width: auto;
            }
            .introdece-img{
                display: block;
                width: 90%;
                float: none;
                padding: 16px 0;
                img{
                    display: block;
                }
                .map{
                    position:static
                //    display: none;
                }
            }
            .other-img{
                margin: 0 auto;
            }
        }
    }
}