.footer1-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: @template-footer-text-color;
  .footer1 {
    .home-page {
      padding: 64px 24px 80px;
    }
  }
  .block {
    padding: 0 32px;
    .logo {
      max-width: 120px;
      img{
        margin-top: -38px;
        transform: scale(150%)
      }
    }
    .slogan {
      font-size: 12px;
      margin-top: -20px;
      min-height:280px;
      .test{
        margin-top: -32px;
        color: white;
        // width: 60%;
        // background:red ;
        height: 150px;
        font-size: 14px;
        .title{
          font-size: 16px;
        }
        .qrcode{
          display: flex;
          margin-top: 16px;
          .first {
            margin-right: 64px;
          }
        }
        ul{
          display: flex;
          white-space: nowrap;
          flex-wrap: wrap;
          li{
            margin-top:8px;
          }
          .left{
            margin-right: 64px;
          }
        }
        img{
          width: 150px;
        }
      }
    }
    >h2 {
      margin-bottom: 24px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 12px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade(@line-color, 10);
    .home-page {
      padding: 0 24px;
      overflow: hidden;
    }
    .copyright {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      height: 80px;
      text-align: center;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .home-page {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
   
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
      .slogan {
        min-height: 480px;
       .test{
         width: 100%;
         margin: 0 auto;
        //  max-width: 165px;
        ul{
          li{
            white-space: normal;
            text-align: left;
          }
        }
        .qrcode{
          display: block;
          .first{
            margin :6px auto;
          }
          div{
            margin: 6px auto;
          }
        }
       }
      }
    }
    .cancel{
      display: none;
    }
    >ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      >li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .home-page {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
      }

      span {
        width: 90%;
      }
    }
  }
}
