.content {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.75);
    padding-top: 69px;
    iframe{
        
        height: calc(100% - 1px);
        width: 100%;
    }
}